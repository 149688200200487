export const wordMatch = (left, right) => {
  console.log(left, right);
  // 0 - полное совпадение
  // 1 - нет совпадений
  left = left.toLowerCase();
  right = right.toLowerCase();

  if (left.includes(right)) {
    return 0;
  }

  // 0 - full match
  // 1 - no match
  left = `\b\b${left.toLowerCase()}\f\f`;
  right = `\b\b${right.toLowerCase()}\f\f`;

  let dist = -4;

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist;
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist;
  }

  return Math.max(0, dist) / (left.length + right.length - 8);
};
export const mergeArrays = (arr1, arr2) => Object.values(arr1.reduce((acc, { id, ...n }) => {
  Object.entries(n).forEach(([k, v]) => {
    acc[id][k] = (acc[id][k] || '') + v;
  });
  return acc;
}, Object.fromEntries(arr2.map(n => [n.id, { ...n }]))));

export const formattedDate = (date, format = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}) => {
  if (!date) return;
  return new Date(date).toLocaleString('Ru-ru', format);
};

export const getNumberOfDays = (endDate) => {
  const date1 = new Date();
  const date2 = new Date(endDate);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  //console.log('diffInDays',diffInDays, 'endDate', endDate)
  return diffInDays;
}
