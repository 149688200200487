<template>
  <div>
    <loader v-if="isLoading" />
    <div
      v-else
      class="culture mt-8"
    >
      <div class="d-flex align-items-center justify-content-between mb-8 mobile-700">
        <r-tabs
          :items="filters"
          v-model="filterSelected"
          @input="changeTab"
        />
        <r-popover
          class="content"
          position="left-bottom"
          click-close
        >
          <template #activator="{ on }">
            <div
              class="content__activator"
              ref="view"
            >
              <r-button-action
                :title="selectedCategory.title"
                v-on="on"
                is-arrow
              />
            </div>
          </template>
          <template #content>
            <div class="px-1">
              <div class="px-3 content__list">
                <div
                  class="content__list__item"
                  :key="category.id"
                  v-for="category of categoryList"
                  @click="selectedCategory = category"
                >
                  {{ category.title }}
                </div>
              </div>
            </div>
          </template>
        </r-popover>
      </div>
      <div class="culture__map-block">
        <div class="culture__map-cont">
          <r-input
            class="mb-6 culture__input-search"
            placeholder="Поиск по адресу"
            v-model="searchText"
            after-icon="search"
          />
          <div
            :class="['culture__map', { 'culture__map--collapsed': isMapCollapsed }]"
            ref="map"
          >
            <div
              class="map__not_found sulguni d-flex align-items-center justify-content-center"
              v-if="objects.length < 1"
            >
              <div class="d-flex flex-direction-column align-items-center justify-content-center">
                <img
                  src="../assets/empty.png"
                  style="width: 144px; height: 144px;"
                  alt=""
                  class="mb-2 empty_map"
                >
                Оповещений нет
              </div>
            </div>
            <rir-map
              v-if="isShowMap"
              v-model="searchText"
              search
              @mapInit="initMap"
              :balloon-component="Balloon"
            >
              <div :key="objects.length > 0">
                <div
                  v-for="item of objects"
                  :key="item.id"
                >
                  <div>
                    <ymap-marker
                      v-for="(marker,index) of item.details.addresses"
                      :key="`_${marker.id}+${index}`"
                      :coords="[`${marker.lat}`, `${marker.lng}`]"
                      :marker-id="`marker_${marker.id}+${index}`"
                      :icon="$markerIcon()"
                      :options="{ hideIconOnBalloonOpen: false }"
                      cluster-name="main"
                      :balloon="{ body: clusterBalloon(marker, item)}"
                      :balloon-component-props="{
                        item: item,
                        address: marker.address
                      }"
                    />
                  </div>
                </div>
              </div>
            </rir-map>
            <button
              class="culture__map-collapse-btn feta"
              @click="isMapCollapsed = !isMapCollapsed"
            >
              <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RirMap from '../components/RirMap.vue';
import BalloonCard from '../components/BalloonCard.vue';
import { wordMatch, getNumberOfDays, formattedDate } from '../helpers/utils';
import Loader from '../components/Loader';
import PlaceApi from '../api/PlaceApi';
import moment from 'moment';
export default {
  name: 'Home',
  components: {
    Loader,
    RirMap
  },
  data() {
    return {
      files: [],
      isMapCollapsed: true,
      searchText: null,
      timeout: null,
      isShowMap: false,
      selectedMarkerId: null,
      coordinates: [47.5259165, 42.1899268],
      filterSelected: {
        id: 'ALL',
        title: 'Все'
      },
      filters: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: 'INWORK',
          title: 'В работе'
        },
        {
          id: 'READY',
          title: 'Готовые'
        },
        {
          id: 'PLANNING',
          title: 'Запланированные'
        }
      ],
      categoryList: [],
      selectedCategory: {
        id: 0,
        title: 'Все категории'
      }
    };
  },
  computed: {
    Balloon() {
      return BalloonCard;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },

    objects() {
      let list = this.$store.state.objects;
      if (!list?.length) return [];

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.details?.addresses[0]?.address || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.addresses[1]?.address || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.addresses[2]?.address || '', this.searchText) <= 0.5
        );
      }

      list = list.filter(el => el?.details?.addresses != null && el?.details?.addresses.length > 0);
      list = list.filter(el => el?.public === '1' || el?.details.public === true);
      // list = list.filter(item => {
      //   if (item.details.dateTo) {
      //     const dateTo = new Date(item?.details?.dateTo) || null;
      //     if (item.details?.timeTo) {
      //       dateTo.setHours(Number(item.details?.timeTo?.slice(0, 2)), Number(item.details?.timeTo?.slice(3, 5)));
      //     } else {
      //       dateTo.setHours(23, 59, 59);
      //     }
      //     return dateTo ? Number(dateTo.getTime()) > Number(new Date().getTime()) : true;
      //   }
      //   return true;
      // });

      if (this.selectedCategory.id !== 0) {
        list = list.filter(el => el?.details?.category?.id === this.selectedCategory.id);
      }
      if (this.filterSelected.id === 'INWORK') {
        list = list.filter(el => {
          const dateFrom = moment(Number(el.details.dateFrom));
          const dateTo = el.details.dateTo ? moment(Number(el.details.dateTo)) : null;
          if (dateTo === null) {
            return true;
          }
          if (el.details?.timeFrom) {
            dateTo.set('hour', Number(el.details?.timeFrom?.slice(0, 2))).set('minute', Number(el.details?.timeFrom?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          if (el.details?.timeTo) {
            dateTo.set('hour', Number(el.details?.timeTo?.slice(0, 2))).set('minute', Number(el.details?.timeTo?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          return moment().isBetween(dateFrom, dateTo);
        });
      }
      if (this.filterSelected.id === 'READY') {
        list = list.filter(el => {
          const dateTo = el.details.dateTo ? moment(Number(el.details.dateTo)) : null;

          if (dateTo === null) {
            return false;
          }
          if (el.details?.timeTo) {
            dateTo.set('hour', Number(el.details?.timeTo?.slice(0, 2))).set('minute', Number(el.details?.timeTo?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          return dateTo.isAfter('2010-10-19');
        });
      }
      if (this.filterSelected.id === 'PLANNING') {
        list = list.filter(el => {
          const date = new Date(el.details.dateFrom);
          date.setHours(Number(el.details?.timeFrom?.slice(0, 2)), Number(el.details?.timeFrom?.slice(3, 5)));
          return getNumberOfDays(date) > 0;
        });
      }
      return list;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },

  async mounted() {
    this.loadObjects();
    await new PlaceApi().getIncidentTypes().then(res => {
      this.categoryList = [
        {
          id: 0,
          title: 'Все категории'
        },
        ...res.all
      ];
    });
  },

  methods: {
    isEndAlert(item) {

    },
    getFormattedDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    clusterBalloon(marker, item) {
      return `
      <div class="r-map-balloon">
        ${item.details?.attachments?.length > 0 ? `<div class="r-map-balloon__img">
          <img src="${item.details.attachments[0].url}" alt="">
        </div>` : ''}
    <div class="r-map-balloon__info">
      <p class="roquefort">
        ${item.description}
      </p>
      <p class="flex align-center сaprino mb-2 opacity-72">
        ${marker.address}
      </p>
      <div class="flex align-items-center mt-1 mozzarella">
      <i class="RIcon icons mr-2 icon-placeholder rir-mayor_16" style="color: var(--rir-rocky);"></i>
       ${item.details.firm || 'Не указано'}
       </div>
        <div class="flex align-items-center mt-1 mozzarella">
        <i class="RIcon icons mr-2 icon-placeholder rir-calendar_16" style="color: var(--rir-rocky);"></i>
         ${(this.getFormattedDate(item.details.dateFrom) || 'Дата не указана') + (item.details.timeFrom ? `, ${item.details.timeFrom}` : '')} –
                ${(this.getFormattedDate(item.details.dateTo) || 'Дата не указана') + (item.details.timeTo ? `, ${item.details.timeTo}` : '')}
        </div>
      <a href="/appeals/add?categoryId=0" target="_blank" class="mt-3 send flex align-items-center">
        <i class="RIcon icons mr-2 icon-placeholder rir-send_16" style="color: var(--rir-rocky);"></i>
        <div class="send opacity-72"> Сообщить о нарушении </div>
      </a>
    </div>
  </div>`;
    },
    redirect() {
      // console.log('ssssssss');
      // window.location.replace('/appeals/add?categoryId=0');
      window.history.pushState(
        {},
        '',
        '/appeals/add?categoryId=0'
      );
    },
    changeTab(tab) {
      // this.loadObjects(tab.id);
    },
    initMap(e) {
      this.map = e;
      this.centeredMap();
    },
    centeredMap() {
      if (this.map) {
        const pointsList = [];
        if (this.objects.length > 0) {
          this.objects.forEach(item => {
            item.details.addresses.forEach(value => {
              pointsList.push([value.lat, value.lng]);
            });
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },
    loadObjects() {
      this.$store.dispatch('loadObjects');
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  &__list {
    /*max-height: 300px;
    overflow: auto;*/
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    &__item {
      display: flex;
      white-space: nowrap;
      align-items: center;
      opacity: .72;
      padding: 14px 16px;
      position: relative;

      &:not(.no_hover):hover {
        opacity: 1;
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          background-color: #3d75e4;
          height: calc(100% - 8px);
          width: 4px;
          top: 4px;
          left: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}

.culture {
  &__cards {
    width: 41.7%;
  }

  &__map-block {
  }

  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    width: 100%;
    //width: calc(58.3% - 32px);
    //margin-left: 32px;
  }

  &__map {
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    height: 556px;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map-block {
      width: 100%;
      margin-left: 0px;
    }

    &__map {
      height: 447px;
      margin-bottom: 32px;
      position: relative;
    }

    &__map--collapsed {
      height: 222px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;
      z-index: 12;
      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .culture {
    &__input-search {
      display: none;
    }
  }
}

// @media screen and (min-width: 600px) {
//   .culture {
//     &__input-search {
//       position: absolute;
//       z-index: 10;
//       background-color: #fff;
//       box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
//       top: 16px;
//       left: 16px;
//     }
//   }
// }

@media screen and (max-width: 599px) {
  .empty_map{
    width: 66px !important;
    height: 66px !important;
  }
  .map__not_found{
    font-size: 13px;
  }
  .culture {
    &__map--collapsed {
      height: 164px;
    }
  }
}
::v-deep .r-map-balloon {
  width: 244px;

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    padding: 16px;
    max-height: 300px;
    overflow-y: auto;
  }
}

::v-deep .text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.textToo {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

::v-deep .send {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3D75E4;
  cursor: pointer;
}
.mobile-700{
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 16px
  }
}
.map__not_found{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: #ffffff70;
}
</style>
