<template>
  <div class="r-map-balloon">
    <div
      class="r-map-balloon__image"
      v-if="hasImages"
    >
      <r-slideshow
        :files="item.details.attachments"
        :is-preview-block="false"
        :is-step-badge="true"
        :is-fill-image="false"
        @click="openViewModalImage"
      />
    </div>
    <div class="r-map-balloon__info">
      <p class="roquefort">
        {{ item.description }}
      </p>
      <p class="flex align-center сaprino mb-2 opacity-72">
        {{ address }}
      </p>
      <div class="flex align-items-center mt-1 mozzarella">
        <r-icon
          icon="mayor"
          fill="rocky"
          class=" icons mr-2 icon-placeholder"
          size="16"
        />
        {{ item.details.firm || "Не указано" }}
      </div>
      <div class="flex align-items-center mt-1 mozzarella">
        <r-icon
          icon="calendar"
          fill="rocky"
          size="16"
          class=" icons mr-2 icon-placeholder"
        />
        {{ (getFormattedDate(item.details.dateFrom) || "Дата не указана") + (item.details.timeFrom ? ', ' + item.details.timeFrom : '') }} –
        {{ (getFormattedDate(item.details.dateTo) || "Дата не указана") + (item.details.timeTo ? ', ' + item.details.timeTo : '') }}
      </div>
      <button
        @click="redirect"
        class="mt-3 send flex align-items-center"
      >
        <r-icon
          icon="send"
          fill="rocky"
          size="16"
          class=" icons mr-2 icon-placeholder"
        />
        <div class="send opacity-72">
          Сообщить о нарушении
        </div>
      </button>
      <div class="mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '../helpers/utils';
import ModalViewImage from './ModalViewImage';

export default {
  name: 'BaloonCard',
  props: {
    item: {
      type: Object
    },
    address: {
      type: String
    }
  },
  computed: {
    hasImages() {
      return this.item.details?.attachments?.length > 0;
    }
  },
  methods: {
    openViewModalImage({ url }) {
      this.$rir.modal.open(ModalViewImage, {
        file: url
      });
    },
    redirect() {
      // console.log('ssssssss');
      // window.location.replace('/appeals/add?categoryId=0');
      window.history.pushState(
        {},
        '',
        '/appeals/add?categoryId=0'
      );
    },
    getFormattedDate(date) {
      console.log(this.item);
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 16px;
    max-height: 300px;
    overflow-y: auto;
  }
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
.textToo {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}
.send {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3D75E4;
  cursor: pointer;
}
</style>
